<template>
    <div class="about text-center">
        <v-img class="d-sm-none stripe" width="100%" src="~@/assets/home/SHU-runner2.jpg"/>
        <section class="about__main">
            <v-container class="about__content">
                <router-link to="/">
                    <img class="d-block logo" src="~@/assets/logo-blue.svg">
                </router-link>
                <h1 v-html="texts.aboutH1"></h1>
            </v-container>
        </section>
        <section class="about__plague --1 size-6">
            {{ texts.aboutHead1 }}
        </section>

        <section class="about__block1">
            <v-img class="d-sm-none" src="~@/assets/about/about-banner.jpg"/>
            <v-container class="pa-sm-0">
                <v-row class="about__block1--row">
                    <v-col cols="12" sm="6">
                        <img class="d-none d-sm-block" height="100%" src="~@/assets/about/about-banner-lg.jpg">
                    </v-col>
                    <v-col cols="12" sm="6" class="about__text size-1" v-html="texts.aboutText1">

                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section>
            <product-page-vegan/>
            <product-page-inspiration/>
        </section>

        <section class="about__text --1 size-1">
            <h2> {{ texts.aboutHead2 }} </h2>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="5">
                        <img src="~@/assets/about/gitar.png">
                    </v-col>
                    <v-col cols="12" sm="7">
                        <div class="about__text--size size-1" v-html="texts.aboutText2">
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="about__motto">
            <img src="~@/assets/about/moon.png">
            <div class="about__motto-content size-6">
                <v-container>
                    <div>
                        {{ texts.aboutMotto }}
                    </div>
                </v-container>
            </div>
        </section>
        <section class="about__pros">
            <v-container>
                <ul>
                    <li v-for="(item, index) of items" :key="index"
                        class="about__pros--item size-5">
                        {{ item.name }}
                    </li>
                </ul>
            </v-container>
        </section>
        <section class="about__block2">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="3" md="4">
                        <v-img src="~@/assets/about/about-tattoo.jpg"/>
                    </v-col>
                    <v-col v-html="texts.aboutMotto2" cols="12" sm="6" md="4" class="about__pros2">

                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                        <v-img src="~@/assets/about/mascara.jpg"/>
                    </v-col>
                </v-row>
            </v-container>
        </section>


    </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {MglMap, MglMarker, MglPopup, MglNavigationControl} from "vue-mapbox";
import Config from "@/config";
import SwitchButton from "../components/switch-button";
import ModalForm from "../components/modal-form";
import ProductPageInspiration from "@/views/product-page/product-page-inspiration";
import ProductPageVegan from "@/views/product-page/product-page-vegan";

let mapbox;

export default {
    name: "about",
    components: {
        ModalForm,
        SwitchButton,
        MglMap,
        MglMarker,
        MglPopup,
        MglNavigationControl,
        ProductPageVegan,
        ProductPageInspiration
    },
    data: () => {
        return {
            isModalOpened: false
        }
    },
    computed: {
        map() {
            return {
                accessToken: Config.MAPBOX.accessToken,
                style: Config.MAPBOX.style,
                zoom: 10,	// default zoom
                center: [this.isWindowsm ? 37.55 : 37.7671, 55.721932],
            }
        },
        point() {
            return {
                offset: [0, -10],
                offsetPopup: [-12, -16],
                coordinates: [37.7671, 55.721932],
            }
        },
        isWindowsm() {
            return this.$store.getters.isWindowsm;
        },
        page() {
            return this.$store.state.page.AboutController;
        },
        texts() {
            return {
                aboutH1: this.$store.state.page.AboutController.texts?.ABOUT_H1,
                aboutHead1: this.$store.state.page.AboutController.texts?.ABOUT_HEAD1,
                aboutHead2: this.$store.state.page.AboutController.texts?.ABOUT_HEAD2,
                aboutText1: this.$store.state.page.AboutController.texts?.ABOUT_TEXT1,
                aboutText2: this.$store.state.page.AboutController.texts?.ABOUT_TEXT2,
                aboutMotto: this.$store.state.page.AboutController.texts?.ABOUT_MOTTO,
                aboutMotto2: this.$store.state.page.AboutController.texts?.ABOUT_MOTTO2,
                //aboutH2_3: this.$store.state.page.AboutController.texts?.ABOUT_H2_3,
                //aboutP: this.$store.state.page.AboutController.texts?.ABOUT_P,
                //aboutList: this.$store.state.page.AboutController.texts?.ABOUT_LIST,
               // contacts: this.$store.state.page.AboutController.texts?.ABOUT_CONTACTS,
               // contact: this.$store.state.page.AboutController.texts?.ABOUT_CONTACT,
            }
        },
        buttons() {
            return {
                formOpen: this.$store.state.common?.buttons?.FORM_OPEN,
            }
        },
        /*aboutItem() {
            return {
                name: this.$store.state.page?.AboutController?.aboutItem?.NAME,
                img: this.$store.state.page?.AboutController?.aboutItem?.DETAIL_PICTURE
            }
        },*/
        items() {
            return this.$store.state.page?.AboutController?.aboutItem?.map(el => {
                return {
                    img: el.DETAIL_PICTURE,
                    name: el.NAME
                };
            });
        },
    },
    methods: {
        onMapLoaded({map}) {
            mapbox = map;

            //this.fitMap();
        },
        /*fitMap() {
            if (!this.items) return;
            const bounds = new Mapbox.LngLatBounds();
            this.items.forEach(el => bounds.extend(el.geo));

            mapbox.fitBounds(bounds, {
                linear: true,
                padding: 50,
                maxZoom: 13
            });
        },*/
    }
}
</script>

<style lang="scss">
.about {
    &__main {
        height: calc(100vh - 38px);
        padding-top: 98px;
        position: relative;
        background-image: url("~@/assets/about/mob_about_bg.jpeg");
        background-size: cover;
        background-position: top center;
        @include up($sm) {
            height: unset;
            padding-top: 187px;
            padding-bottom: 75px;
            background-image: url("~@/assets/about/1728_about us_bg.jpeg");
        }

        & img {
            margin: 0 auto;
            @include up($sm) {
                height: 90px;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        z-index: 2;
        position: relative;
    }

    & h1 {
        @include up($sm) {
            max-width: 1159px;
            margin: 40px auto 0;
        }
    }

    &__plague {
        &.--1 {
            background-color: $black;
            padding: 105px 0;
            position: relative;
            text-align: center;
            color: $light;
            z-index: 0;
            @include up($sm) {
                padding: 30px 0;
            }

            &:before {
                position: absolute;
                content: "";
                background-image: url("~@/assets/star-red.png");
                top: 15px;
                left: 50%;
                width: 43px;
                height: 43px;
                margin-right: -50%;
                transform: translate(-50%, 50%);
                @include up($sm) {
                    top: 35%;
                    left: 300px;
                    transform: unset;
                }
            }

            &:after {
                position: absolute;
                content: "";
                bottom: 15px;
                left: 50%;
                width: 43px;
                height: 43px;
                transform: translate(-50%, -50%);
                background-image: url("~@/assets/star-red.png");
                @include up($sm) {
                    position: absolute;
                    content: "";
                    top: 35%;
                    right: 300px;
                    left: unset;
                    transform: unset;
                }
            }
        }
    }


    &__block1 {
        &--row {
            @include up($sm) {
                height: 100%;
            }
        }

        & img {
            width: 100%;

            @include up($sm) {
                object-fit: cover;
            }
        }
    }

    &__text {
        padding: 65px 0;
        @include up($sm) {
            padding: 160px 110px 120px 95px;
        }

        &--size {
            @include up($md) {
                padding-left: 110px;
            }
        }

        &.--1 {
            padding: 75px 0 95px;
            background: #F3E5CE;
            @include up($sm) {
                & img {
                    width: 434px;
                    filter: drop-shadow(0px 0px 30px rgba(169, 11, 29, 0.3));
                }
            }

            & h2 {
                display: inline-block;

                &:before {
                    @include up($sm) {
                        top: 25px;
                        left: -50px;
                    }
                }

                &:after {
                    @include up($sm) {
                        position: absolute;
                        content: "";
                        width: 43px;
                        height: 43px;
                        background-image: url("~@/assets/star-blue.png");
                        top: 45px;
                        right: -70px;
                    }
                }
            }
        }

        & p {
            text-align: left;

            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }

        & h2 {
            color: $blue-1;
            margin-bottom: 0;
            @include up($sm) {
                font-size: 64px;
                line-height: 77px;
                margin-bottom: 100px;
            }

            &:before {
                background-image: url("~@/assets/star-blue.png");
            }
        }
    }

    &__motto {
        padding: 55px 0;
        position: relative;
        @include up($sm) {
            padding: 115px 0;
            & img {
                width: auto;
                height: 373px;
            }
        }

        &:before {
            position: absolute;
            content: "";
            top: -50px;
            width: 100%;
            height: 100%;
            background-image: url("~@/assets/about/big-star.png");
            @include up($sm) {
                background-image: unset;
            }
            @include up($md) {
                border: 3px solid $light;
                width: 1px;
                height: 150px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__motto-content {
        text-align: center;
        color: $blue-1;
    }

    &__pros {
        padding: 55px 0;
        background-color: $black;
        text-align: center;
        position: relative;
        color: #F6EFE9;
        @include up($sm) {
            padding: 25px 0;
            &:after {
                position: absolute;
                content: "";
                border: 3px solid $black;
                width: 1px;
                height: 200px;
                bottom: -98px;
                left: 50%;
                transform: translate(50%, 50%);
            }

            & ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &--item {
            position: relative;
            margin: auto;
            @include up($sm) {
                margin: unset;
                padding: 10px 30px;
            }

            &:not(:last-child) {
                margin-bottom: 75px;
                @include up($sm) {
                    margin-bottom: 0;
                }

                &:after {
                    position: absolute;
                    content: "";
                    width: 43px;
                    height: 43px;
                    bottom: -80px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url("~@/assets/star-white.png");
                    @include up($sm) {
                        right: 0;
                        left: unset;
                        bottom: unset;
                        top: 50%;
                        transform: translate(50%, -50%);
                    }

                }
            }
        }

    }

    &__block2 {
        & .container {
            padding: 0;
            max-width: unset;
        }

        @include up($sm) {
            & .container {
                padding: 0 50px;
                //max-width: 1720px;
            }
        }
        @include up($md) {
            & .container {
                padding: 0 68px;
                max-width: 1720px;
            }
        }

        & .v-image {
            height: 100%;
        }
    }

    &__pros2 {
        padding: 30px 0 40px;
        color: #000000;

        @include up($sm) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 215px 110px 55px;
        }
        @include up($md) {
            padding: 250px 50px 170px;
        }

        & p {
            margin-top: 40px;
        }
    }
}
</style>