<template>
    <div class="product-page-vegan">
        <v-container>
            <v-row>
                <v-col cols="12"  sm="6" class="relative"><img class="product-page-vegan__img"
                                                              src="~@/assets/product/product-page-vegan/1.png"></v-col>
                <v-col v-html="texts.PRODUCT_VEGAN" cols="12" sm="6" class="text-left">

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "product-page-vegan",
    computed: {
        texts() {
            return this.$store.state.page?.AboutController?.texts;
        }
    }
}
</script>

<style lang="scss">
.product-page-vegan {
    color: $black;
    position: relative;
    z-index: 0;
    padding-bottom: 35px;
    @include up($sm) {
        padding: 150px 0;
    }

    &__img {
        position: relative;
        top: -30px;
        left: 50%;
        //transform: translateX(-50%);
        width: 450px;

        @include up($sm) {
            width: 100%;
            position: absolute;
            transform: unset;
            top: -160px;
            left: unset;
            right: 50px;
        }
    }

    h2 {
        margin-bottom: 60px;
        @include up($sm) {
            margin-bottom: 170px;
        }

        &:before {
            transform: unset;
            left: 0;
            top: -35px;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
        width: 100%;
        height: 100%;
        background: radial-gradient(52% 52% at 50% 50%, #EDDDB7 40.54%, rgba(237, 221, 183, 0) 100%);
        @include up($sm) {
            background: radial-gradient(50% 50% at 50% 50%, #EDDDB7 38.54%, rgba(237, 221, 183, 0) 100%);
        }
    }
}
</style>