import { render, staticRenderFns } from "./product-page-vegan.vue?vue&type=template&id=ad39ac32&"
import script from "./product-page-vegan.vue?vue&type=script&lang=js&"
export * from "./product-page-vegan.vue?vue&type=script&lang=js&"
import style0 from "./product-page-vegan.vue?vue&type=style&index=0&id=ad39ac32&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports