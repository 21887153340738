<template>
  <section class="product-page-inspiration text-left">
    <v-img src="@/assets/product/product-page-inspiration/1.jpg" class="d-sm-none mb-10"/>
    <v-container>
    <v-row>
      <v-col cols="12" sm="7" class="mb-15 pl-sm-10 d-none d-sm-block" order-sm="2">
        <v-img src="@/assets/product/product-page-inspiration/2.jpg"/>
      </v-col>
      <v-col v-html="texts.PRODUCT_INSPIRATION" cols="12" sm="5" order-sm="1" class="mt-16 mt-sm-2 mr-sm-0 pr-sm-0 pt-sm-10 ">

      </v-col>
    </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "product-page-inspiration",
    computed: {
        texts() {
            return this.$store.state.page?.AboutController?.texts;
        }
    }
}
</script>

<style lang="scss">
.product-page-inspiration {
  h2 {
      margin-bottom: 60px;
    @include up($sm) {
      margin-bottom: 130px;
    }
      @include up($lg) {
          margin-bottom: 230px;
      }
    &:before {
      transform: unset;
      left: 0;
        top: -35px;
    }
  }

}
</style>